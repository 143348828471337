.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

&__strength{
    position: absolute;
    width: 0%;
    height: 4px;
    bottom: -8px;
    left: 0;
    background: transparent;
    transition: all 300ms ease-in-out;

    &[data-score="null"]{
      width: 0;
      background: red;
    }

    &[data-score="0"]{
      width: 5%;
      background: $scoreRed;
    }
    &[data-score="1"]{
      width: 25%;
      background: $scoreRed;
    }
    &[data-score="2"]{
      width: 50%;
      background: $scoreYellow;
    }
    &[data-score="3"]{
      width: 75%;
      background: $scoreGreen;
    }
    &[data-score="4"]{
      width: 100%;
      background: $scoreGreen;
    }
  }
